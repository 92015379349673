import { Icon } from '@iconify/react';
import React from 'react'
import { Link } from 'react-router-dom';
import Flags from '../Home/Flags';
import Flags2 from '../Home/Flags/Flags2';
import FlagsBanner from '../Home/Flags/FlagsBanner';
import ShopFlags from '../Home/Flags/ShopFlags';
import Footer from '../Home/Footer';
import GearList from '../Single-Page/New-Gear/gear-list';
import CardContainer from './CardContainer';
import ProductList from './product-list';

const MarketHome = () => {
  return (
    <div className="mb-[8em] ">
      {/* <MarketCategories value={category1} select={1} /> */}

      <Flags
        m1="Essence In Every Bottle"
        m2="All Sold Items are Authenticated"
        m3="Shop Fragrances Here"
        m4={3}
        m5=""
      />
      <Flags2
        m1="Essence In Every Bottle"
        m2="All Sold Items are Authenticated"
        m3="Shop Fragrances Here"
        m4={3}
        m5=""
      />
      {/* <MarketCategories value={category1} select={2} /> */}

      <div className="mt-4">
        <Link to={"/market"}>
          <p className="text-sm md:text-base font-bold flex mb-3">
            Find Your Fragrance
            <Icon
              icon="ph:arrow-right-duotone"
              className="my-auto ml-1 text-xl"
            />
          </p>
        </Link>
        <div className="h-0 border-t border-slate-400/20" />
        <ShopFlags />
      </div>

      <div className="lg:mt-10 mt-4">
        <Link to={"/market"}>
          <p className="text-sm md:text-base font-bold flex">
            Recommended For You{" "}
            <Icon
              icon="ph:arrow-right-duotone"
              className="my-auto ml-1 text-xl"
            />
          </p>
        </Link>
        <div className="h-0  border-t border-slate-400/20" />
        <ProductList
          value={list}
          className="md:grid-cols-3 lg:grid-cols-6 xl:grid-cols-6 "
        />
      </div>
      <img
        className="w-[2/3] h-20 mx-auto object-cover mt-8 rounded-lg"
        src="/assets/imgs/home/banner-2.png"
        alt="ad"
      />
      <div className="lg:mt-20 mt-4">
        <Link to={"/market"}>
          <p className="text-sm md:text-base font-bold flex mb-1">
            Shop Top Brands{" "}
            <Icon
              icon="ph:arrow-right-duotone"
              className="my-auto ml-1 text-xl"
            />
          </p>
        </Link>

        <div className="h-0 mb-5  border-t border-slate-400/20" />
        <CardContainer />
      </div>

      <div className="lg:mt-20 mt-4">
        <p className="text-sm md:text-base font-bold flex ">
          Most Popular Scents
          <Icon
            icon="ph:arrow-right-duotone"
            className="my-auto ml-1 text-xl"
          />
        </p>
        <div className="h-0  border-t border-slate-400/20" />
        <GearList
          value={prodList}
          className="md:grid-cols-3 lg:grid-cols-6 xl:grid-cols-6 "
        />
      </div>
      <img
        className="w-[75%] h-20  mx-auto object-cover my-10 rounded-lg"
        src="/assets/imgs/home/banner-3.png"
        alt="ad"
      />
      <div className="lg:mt-18 mt-4">
        <p className="text-sm md:text-base font-bold flex">
          Top Women{" "}
          <Icon
            icon="ph:arrow-right-duotone"
            className="my-auto ml-1 text-xl"
          />
        </p>
        <div className="h-0  border-t border-slate-400/20" />
        <ProductList
          value={list2}
          className="md:grid-cols-3 lg:grid-cols-6 xl:grid-cols-6 "
        />
      </div>
      <img
        className="w-full h-full mx-auto object-cover mt-12 rounded-lg"
        src="/assets/imgs/home/banner-8.png"
        alt="ad"
      />
      {/* <Footer/> */}
    </div>
  );
}

export default MarketHome



export const prodList = [
  {
    id: "FRG100004",
    brandName: "Christian Dior",
    brandAbr: "DIOR",
    model: "J'adore Dior",
    img: "https://fimgs.net/mdimg/perfume/375x500.210.jpg",
    fragUrl: "https://www.fragrantica.com/perfume/Dior/J-adore-210.html",
    formula: "EDP",
    year: 1999,
    category: "FLORAL",
    popularRate: 9.8,
    status: "OG",
    gender: "FEMALE",
    rate: 4.01,
    price: 193.95,
    votes: 25802,
    createdDateTime: "2024-09-25T01:02:14.495",
  },
  {
    id: "FRG100022",
    brandName: "Yves Saint Laurent",
    brandAbr: "YSL",
    model: "Black Opium",
    img: "https://fimgs.net/mdimg/perfume/375x500.25324.jpg",
    fragUrl:
      "https://www.fragrantica.com/perfume/Yves-Saint-Laurent/Black-Opium-25324.html",
    formula: "EDP",
    year: 2014,
    category: "ORIENTAL",
    popularRate: 9.75,
    status: "active",
    gender: "FEMALE",
    rate: 4.15,
    price: 173.05,
    votes: 25906,
    createdDateTime: "2024-09-25T00:50:37.41",
  },
  {
    id: "FRG100055",
    brandName: "Dolce & Gabbana",
    brandAbr: "D&G",
    model: "Light Blue",
    img: "https://fimgs.net/mdimg/perfume/375x500.485.jpg",
    fragUrl:
      "https://www.fragrantica.com/perfume/Dolce-Gabbana/Light-Blue-485.html",
    formula: "EDT",
    year: 2001,
    category: "FRESH",
    popularRate: 9.55,
    status: "CLASSIC",
    gender: "UNISEX",
    rate: 4,
    price: 30.99,
    votes: 33792,
    createdDateTime: "2024-10-22T11:13:02.514",
  },
  {
    id: "FRG100086",
    brandName: "Chanel",
    brandAbr: "CHANEL",
    model: "Bleu de Chanel EDP",
    img: "https://fimgs.net/mdimg/perfume/375x500.25967.jpg",
    fragUrl:
      "https://www.fragrantica.com/perfume/Chanel/Bleu-de-Chanel-Eau-de-Parfum-25967.html",
    formula: "EDP",
    year: 2014,
    category: "FRESH",
    popularRate: 9.3,
    status: "OG",
    gender: "MALE",
    rate: 4.64,
    price: 136.65,
    votes: 18505,
    createdDateTime: "2024-10-23T14:05:20.999",
  },
  {
    id: "FRG100076",
    brandName: "Carolina Herrera",
    brandAbr: "CH",
    model: "Good Girl",
    img: "https://fimgs.net/mdimg/perfume/375x500.39681.jpg",
    fragUrl:
      "https://www.fragrantica.com/perfume/Carolina-Herrera/Good-Girl-39681.html",
    formula: "EDP",
    year: 2016,
    category: "ORIENTAL",
    popularRate: 9.15,
    status: "CLASSIC",
    gender: "FEMALE",
    rate: 4.09,
    price: 51.95,
    votes: 19405,
    createdDateTime: "2024-10-22T20:07:48.332",
  },
  {
    id: "FRG100039",
    brandName: "Maison Francis Kurkdjian",
    brandAbr: "MFK",
    model: "Baccarat Rouge 540",
    img: "https://fimgs.net/mdimg/perfume/375x500.33519.jpg",
    fragUrl:
      "https://www.fragrantica.com/perfume/Carolina-Herrera/Good-Girl-39681.html",
    formula: "EDP",
    year: 2016,
    category: "ORIENTAL",
    popularRate: 9.15,
    status: "CLASSIC",
    gender: "UNISEX",
    rate: 4.09,
    price: 201.95,
    votes: 19405,
    createdDateTime: "2024-10-22T20:07:48.332",
  },
];





const list = [
  {
    id: "PRD100242",
    postId: "ITM100212",
    name: "Intense Cafe",
    price: 89.99,
    usage:100,
    sellerId: "US100027",
    size: 3.4,
    formula: "EDP",
    images: "https://fimgs.net/mdimg/perfume/375x500.18021.jpg",
    condition: "NEW",
  },
  {
    id: "PRD100128",
    postId: "ITM100100",
    name: "Libre Intense",
    price: 62.99,
    usage:100,
    sellerId: "US100032",
    size: 3,
    formula: "EDP",
    images: "https://fimgs.net/mdimg/perfume/375x500.62318.jpg",
    condition: "NEW",
  },
  {
    id: "PRD101060",
    postId: "ITM101030",
    name: "Greenwich Village",
    price: 289.99,
    usage:100,
    sellerId: "US100038",
    size: 3.4,
    quantity: 4,
    total: 1,
    formula: "PAR",
    images: "https://fimgs.net/mdimg/perfume/375x500.54157.jpg",
    condition: "NEW",
  },
  {
    id: "PRD100532",
    postId: "ITM100139",
    name: "Yellow Diamond",
    price: 53.99,
    usage:100,
    sellerId: "US100025",
    size: 1.7,
    formula: "EDT",
    images: "https://fimgs.net/mdimg/perfume/375x500.13064.jpg",
    condition: "NEW",
  },
  {
    id: "PRD100460",
    postId: "ITM100430",
    name: "Chance Eau Fraiche",
    price: 159.99,
    usage:100,
    sellerId: "US100019",
    size: 3.4,
    formula: "EDP",
    images: "https://fimgs.net/mdimg/perfume/375x500.83980.jpg",
    condition: "NEW",
  },
  {
    id: "PRD101056",
    postId: "ITM101026",
    name: "Red Tobacco",
    price: 109.99,
    usage:100,
    sellerId: "US100038",
    size: 4,
    formula: "EDP",
    images: "https://fimgs.net/mdimg/perfume/375x500.46663.jpg",
    condition: "NEW",
  },
];

export const list2 = [
  {
    id: "PRD100232",
    postId: "ITM100202",
    name: "Xerjoff Erba Pura EDP",
    price: 189.95,
    usage: 100,
    sellerId: "US100026",
    size: 3.4,
    formula: "EDP",
    images: "https://fimgs.net/mdimg/perfume/375x500.55157.jpg",
    condition: "NEW",
  },
  {
    id: "PRD100957",
    postId: "ITM100927",
    name: "Carmina",
    price: 199.99,
    usage: 100,
    sellerId: "US100026",
    size: 1,
    formula: "EDP",
    images: "https://fimgs.net/mdimg/perfume/375x500.84206.jpg",
    condition: "NEW",
  },
  {
    id: "PRD100206",
    postId: "ITM100176",
    name: "Lost Cherry",
    price: 142.99,
    usage: 100,
    sellerId: "US100025",
    size: 3.4,
    formula: "PAR",
    images: "https://fimgs.net/mdimg/perfume/375x500.51411.jpg",
    condition: "NEW",
  },
  {
    id: "PRD100044",
    postId: "ITM100010",
    name: "Dior Addict EDP",
    price: 129.24,
    usage: 100,
    sellerId: "US100019",
    size: 3.4,
    formula: "EDP",
    images: "https://fimgs.net/mdimg/perfume/375x500.15270.jpg",
    condition: "NEW",
  },
  {
    id: "PRD100215",
    postId: "ITM100185",
    name: "DELINA",
    price: 149.99,
    usage: 100,
    sellerId: "US100019",
    size: 2.5,
    formula: "EDP",
    images: "https://fimgs.net/mdimg/perfume/375x500.43871.jpg",
    condition: "NEW",
  },
  {
    id: "PRD101180",
    postId: "ITM100150",
    name: "Chocolate Greedy",
    price: 89.99,
    usage: 100,
    sellerId: "US100019",
    size: 3.4,
    formula: "EDT",
    images: "https://fimgs.net/mdimg/perfume/375x500.2186.jpg",
    condition: "NEW",
  },
];