import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { notifySelect } from "../../../redux/features/notify/notifySlice";
import { inviteUsers, joinWaitlist } from "../../../redux/features/post/inviteSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import GradientBtn from "../../buttons/GradientBtn";
import { Form, FormControl, FormField, FormItem, FormMessage } from "../../ui/form";
import DialogHeader from "../dialog-header";
import { Input } from "../input";
import Loader from "../Loader/loader";
import { useOktaAuth } from "@okta/okta-react";
import { Loading } from "../Widget/Loading";
import { Icon } from "@iconify/react";
import Card from "../Card/Card";
import { Privacy } from "../privacy";

const formSchema = z.object({
  senderId: z.string().optional(),
  recipientEmail: z.string(),
  type: z.string(),
});

const JoinWaitList = () => {
  const dispatch = useAppDispatch();
  const { authState } = useOktaAuth();
  const { email, userId} = useAppSelector((state) => state.userProfile);
  const { waitError,waitLoading,waitSent } = useAppSelector((state) => state.inviteUser);
  const formSchema = z.object({
    senderId: z.string().optional(),
    recipientEmail: z.string().default(email),
    type: z.string(),
  });
  const form = useForm({
    resolver: zodResolver(formSchema),
  });

  
  function onSubmit(values: any) {
      dispatch(joinWaitlist(values));
      dispatch(
        notifySelect({
          title: "Invite Sent!",
          type: 0,
          open: true,
        })
      );
  }

  useEffect(() => {
    
    if (authState?.isAuthenticated) {
      form.setValue("recipientEmail", email);
      form.setValue("senderId", userId);
    } else {
      form.setValue("senderId", "Guest");
    }
    if (waitError) {
      dispatch(
        notifySelect({
          title: "Error Sending 😅",
          type: 1,
          open: true,
        })
      );
    }
  }, [email, waitError]);

  return (
    <>
      {waitSent ? (
        <Card>
          <Icon
            icon={"icon-park-twotone:success"}
            className="h-12 w-12 text-green-400 mx-auto my-3"
          />
          <h1 className="text-center">
            Success! You have been added to the waitlist, thanks!
          </h1>
        </Card>
      ) : (
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <DialogHeader />
            <h2 className="text-center mb-2 text-black text-3xl font-semibold">
              Join the waitlist
            </h2>
            <p className="text-center text-red-500 text-base font-bold">
              Seller mode opens March 1st
            </p>
            <h4 className="text-center text-black text-base font-normal">
              Keep 95% of earnings!
            </h4>
            <div className="grid grid-cols-2 gap-1 my-4 ">
              <div className="w-full">
                <p className=" text-[13px] font-semibold my-1">
                 Type of Seller?
                </p>

                <FormField
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <>
                          <FormMessage className="text-[10px]" />
                          <Privacy
                            level={level}
                            defaultValue={"Casual"}
                            onOptionSelect={field.onChange}
                          />
                        </>
                      </FormControl>
                    </FormItem>
                  )}
                  name={"type"}
                  control={form.control}
                />
              </div>
            </div>

            <FormField
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      autoFocus
                      noLabel
                      {...field}
                      type="email"
                      placeholder="Enter Email"
                      className="w-full md:w-[460px] h-12 px-3 mt-4 bg-zinc-100 rounded-[11px] border outline-accentYellow-500 border-slate-400/20"
                    />
                  </FormControl>
                </FormItem>
              )}
              name={"recipientEmail"}
              key={"email"}
              control={form.control}
            />
            {waitLoading ? (
              <Loading />
            ) : (
              <GradientBtn
                type="submit"
                label={"Join Waitlist"}
                className="mx-auto block mt-14 mb-10"
              />
            )}
            <p className="text-center md:w-[460px] text-zinc-600 text-sm font-medium">
              First 100 added sellers will earn 5% fees max on all sales! Invite
              friends to join the party.
            </p>
          </form>
        </Form>
      )}
    </>
  );
};

export default JoinWaitList;


const level = [
  {
    label: "Casual",
    value: "Casual",
  },
  {
    label: "Active",
    value: "Active",
  },
  {
    label: "Shop",
    value: "Shop",
  },
  {
    label: "Vender",
    value: "Vender",
  },
];


