import {useState } from "react";
import { Link } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";

import { RatingStars } from "./details";
import { Chip } from "@mui/material";
import { Icon } from "@iconify/react";

export const GearCard = ({
  alt = false,
  info,
  isDelete = true,
}: {
  alt?: boolean;
  info: any;
  isDelete?: any;
}) => {
  const [data, setData] = useState([]);
  const [isUser, setIsUser] = useState(false);
  const [loading, setLoading] = useState(false);

  function getRandomNumber() {
    return Math.floor(Math.random() * (35 - 18 + 1)) + 18;
  }

  return (
    <div className=" bg-white shadow-md rounded-lg duration-500 hover:scale-105 hover:shadow-xl ">
      <div className="h-full w-full bg-neutral-100 rounded-lg border backdrop-blur-[20px] relative">
        <Link to={`/single/new-gear/${alt ? info._id : info.id}`}>
          {loading ? (
            <Skeleton
              className="my-2 mx-auto"
              variant="rounded"
              width={180}
              height={130}
            />
          ) : (
            <img
              className="h-full w-60 object-cover rounded-t-lg "
              src={info.img}
              alt=""
            />
          )}
        </Link>
      </div>
      <Link to={`/single/new-gear/${alt ? info._id : info.id}`}>
        <div className="md:px-3 px-2 md:py-3 py-1">
          <div className="justify-between flex">
            <span className="flex text-gray-400 md:mr-3 uppercase text-[9px] md:text-[8px]  lg:text-2xs">
              {info.year} • {info.formula} • {info.gender}{" "}
            </span>
            <Chip
              icon={
                <Icon className={"text-2xs"} icon={"hugeicons:sale-tag-01"} />
              }
              sx={{
                bgcolor: "background.paper",
                fontSize: "10px",
              }}
              className="text-2xs "
              color="primary"
              size="small"
              label={`${getRandomNumber()}0+ Sold`}
              variant="outlined"
            />
          </div>

          <p className="text-sm md:text-base font-semibold text-black truncate block capitalize">
            {info.model}
          </p>
          <div className="flex items-center">
            <p className="text-base font-light text-zinc-500 italic cursor-auto my-">
              {info.brandAbr}
            </p>

            {info.status !== "active" ? (
              <Chip
                sx={{
                  fontSize: "10px",
                }}
                className="text-2xs ml-4"
                color={
                  info.status === "GOLD"
                    ? "warning"
                    : info.status === "TREND"
                    ? "primary"
                    : "default"
                }
                size="small"
                label={info.status}
                variant="outlined"
              />
            ) : null}

            <div className="ml-auto text-green-300">
              <RatingStars numberOfReviews={info.rate.toFixed(2)} />
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};
